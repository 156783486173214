import React, { useEffect, useState } from 'react';
import { List, Space, Rate } from 'antd';
import axios from "axios";
//import { AiOutlineStar, AiOutlineLike, AiOutlineMessage} from 'react-icons/ai';

export function ReviewList ({product}) {

    const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        axios.post(API_URL+"/productReviews", 
                  JSON.stringify(product),
                  {headers: { "Content-Type": "application/json" }}
  
            )
            .then((res) => {
                //console.log(res.data);
                setReviews(res.data);
        });
    }, []);

    const IconText = ({value, text }) => (//{React.createElement(icon)}
        <Space>
          
          <Rate disabled allowHalf defaultValue={value} />
          {text}
        </Space>
      );

    return (
        reviews.length!==0 && Array.isArray(reviews) ? (
        <List >
            {reviews.map(review => (
            <List.Item key='review'>
                <List.Item.Meta
                    title={'customer ' + review.customerID + ' on ' + review.date}
                    description={<IconText value={review.stars} text={'Rating: '+review.stars} key="list-vertical-star-o" />}
                />
                <h3>{review.review}</h3>
            </List.Item>
            ))}
        </List>) : (<div style={{margin:'auto'}}>
                    <div>
                        No Reviews Yet.
                    </div>
                </div>)
    );
}