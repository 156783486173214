import React from 'react';
import { Button, Form, Divider, Result, Modal, message } from "antd";
import axios from "axios";
//import './payment.css';
import { useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useStripe, useElements, PaymentElement, } from '@stripe/react-stripe-js';


export function Payment ({order}) {

    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [msg, setMsg] = useState('');
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const ord = order;
    //const clientSecret = props.clientSecret;
    var result = {};
    //console.log('Payment clientSecret: '+clientSecret);
    //console.log('Payment ord: '+JSON.stringify(ord));
    const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;

    useEffect(()=> {
      if (paymentStatus===true) {
      
        axios.post(API_URL + "/confirmOrder", 
                  ord,
                  {headers: { "Content-Type": "application/json" }}
  
            )
            .then((res) => {
                //console.log(res.data);
        });

    }}, [paymentStatus]);

    const onCancelPayhandler = (e) => {
        e.preventDefault();
        axios.post(API_URL + "/cancelPaymentIntent", 
                  ord,
                  {headers: { "Content-Type": "application/json" }}
  
            )
            .then((res) => {
                //console.log(res.data);
                setIsModalOpen(true);
        });
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        
        message.loading({
          content: 'Payment being processed...',
          duration: 0,
        });

        if (!stripe || !elements) {
            message.destroy();
            return;
        };

        result = await stripe.confirmPayment({
            elements,
            confirmParams:{
                //return_url: 'https://localhost:3000/paymentConfirmed',
            },
            redirect: 'if_required',
        });
        //console.log('stripe: '+JSON.stringify(stripe));
        //console.log('payment result: '+JSON.stringify(result));
        if (result.error) {
          message.destroy();
          alert(result.error.message);
        }
        else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
            setPaymentStatus(true);
            
        }

    };

    if (paymentStatus===true) {
      message.destroy();
      return (
      <div className='aboutus'>
      <Result className='containers'
        status="success"
        title="Your payment is Successful!"
        subTitle=""
        extra={[
          <Button type="primary" key="console">
            <Link to='/userInfo/pendingOrders'>Check for my order </Link>
          </Button>,
        ]}
      />
      </div>);
    };


    return (
      <div style={{height:'83vh', alignContent:'center'}}>
        
        <h1>Pay</h1>
        <div className="payContainer">
        <h4>Please Enter Your Bank Card Details</h4>
          <Form style={{width:'92%', margin:'auto'}}>
            <PaymentElement />
          </Form>
          {msg && <div id="payment-message">{msg}</div>}
        </div>
        <div className='down'>
          <div className="divider">
            <Divider />
          </div>
          <div style={{display: 'flex', justifyContent:'space-between',margin:'auto', marginBottom:'3%', width:'92%'}}>
           <div className="backButton">
             <Button style={{backgroundColor:'gray', borderColor:'gray'}} onClick={()=> navigate("/checkout", {from: "pay"})} type="primary" shape="round" size="large"
               >Back</Button>
            </div>
            <div>
             <Button onClick={onCancelPayhandler} type="link" size="large"
               >Cancel Payment</Button>
               <Modal open={isModalOpen} onOk={handleOk}>
                   <p>You have canceled the payment.</p>
              </Modal>
            </div>
            <div className="pay">
              <Button style={{backgroundColor:'green', borderColor:'green'}} type="primary" shape="round" size="large" onClick={onSubmitHandler}>Pay</Button>
            </div>
          </div>
        </div>
        
      </div>  
    )
};