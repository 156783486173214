import React from 'react';
import "antd";
import { CartItem } from './cartItem';
import "./cart.css";
import { useCart } from './cart-hooks'; 

export function CartList({cartItems}) {

    const {total} = useCart();
    
    return (
        <div className='table_container'>
           <table className='table'>
            <thead>
                <tr>
                    <td></td>
                    <td width='14%' style={{wordWrap:'break-word'}}>Items</td>
                    <td width='14%' style={{wordWrap:'break-word'}}>Description</td>
                    <td>Unit Price</td>
                    <td>Quantity</td>
                    <td>Subtotal</td>
                    <td>Remove</td>
                </tr>
            </thead>
            <tbody>
                
                {
                    cartItems.length===0 ? (
                        <tr>
                            <td colSpan="6">Your cart is empty.</td>
                        </tr>    
                    ) : (
                        
                    cartItems.map((item, i) => (<CartItem key={i} item={item} />))
                    
                    )}
                    
                
                
            </tbody>         
            <tfoot> 
                <tr>
                    <td colSpan="6" align='right'>Total: {total}</td>
                </tr>
            </tfoot>
           </table>
        </div>
    )
}