import React, {useRef} from "react";
import {useState} from "react";
import {useNavigate} from 'react-router-dom';
import {Alert, Button, Form, Input} from "antd";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import "./signup.css";


export function Signup() {

const [info, setInfo] = useState({
    user_name: "",
    mobile_number: "",
    email: "",
    role: "user",
    password: "",
    isLoading: "",
});
const ReCAPTCHARef = useRef(null);
const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL + '/signup';

const [msg, setMsg] = useState('');
const [confirm, setConfirm] = useState('');

const onChangehandler = (e) => {
    e.preventDefault();
    var name = e.target.name;
    var value = e.target.value;
    setInfo(previous => {
        return {...previous, [name]: value};
    });
};

const navigate = useNavigate();

const onSubmithandler = (e) => {
    e.preventDefault();
    if (info.email==="" || info.password==="") {
        setMsg('Please enter your email or password.');
        return;
    }
    const RecaptchaToken = ReCAPTCHARef.current.getValue();
    ReCAPTCHARef.current.reset();
    setInfo({isLoading: true});
    axios.post(API_URL, {
        recaptchaToken: RecaptchaToken,
        user_name: info.user_name,
        mobile_number: info.mobile_number,
        email: info.email,
        role: info.role,
        password: info.password,
    })  
        .then((response) => {
            setInfo({isLoading: false});
            if (response.status === 200) {
                setConfirm(response.data);
                setTimeout(() => {
                    navigate('/login');
                }, 3000);     
            }
         }).catch(() => {
            //console.log(error);
            setMsg('Failed to sign up. Account already existed!');
        });;
}

    return (
        
        <div className="content">
        <div className="signup">
        <div className="containers">
          <Form className="form-box">
             <Form.Item label='User Name' name="user_name" rules={[{required: true, message:'Please enter your account name.'}]}>
              <Input type="user_name"
                     name="user_name"
                     value={info.user_name}
                     onChange={onChangehandler} />
             </Form.Item>
             <Form.Item label='Mobile Number' name="mobile_number" rules={[{required: true, message:'Please enter your mobile number.'}]}>
              <Input type="mobile_number"
                     name="mobile_number"
                     value={info.mobile_number}
                     onChange={onChangehandler} />
             </Form.Item>
             <Form.Item label='Email' name="email" rules={[{required: true, message:'Please enter your email.'}]}>
              <Input type="email"
                     name="email"
                     value={info.email}
                     onChange={onChangehandler} />
             </Form.Item>
             <Form.Item label='Password' name="password" rules={[{required: true, message:'Please enter your password.'}]}>
              <Input.Password type="password"
                              name="password"
                              value={info.password}
                              onChange={onChangehandler}
                              iconRender={(visible) => (visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />)}
                              style={{width:'100%'}} />
             </Form.Item>
             <ReCAPTCHA sitekey='6LdG1K4pAAAAAD3nU86VpMzJp-Vlx5V5NewQ6oR_' ref={ReCAPTCHARef}/>
             {confirm===""? (<div></div>): <Alert message={confirm} 
                                             type='success' 
                                             style={{margin: 'auto', marginBottom:'1%'}} showIcon closable/>}
             {msg===""? (<div></div>): <Alert message={msg} 
                                             type='warning' 
                                             style={{margin: 'auto', marginTop:'1%', marginBottom:'1%'}} showIcon closable/>}
             <Button style={{marginTop:'2%'}} className="text-center mb-4"
                     color="success"
                     onClick={onSubmithandler}>Signup</Button>
          </Form>   
      </div>
    </div>
  </div>   
        
    );
}