import React from "react";
import {Routes, Route} from "react-router-dom";
import Layout from "./Layout.js";
import {Home} from "./Home.js";
import {Contact} from "./Contact.js";
import {Aboutus} from "./Aboutus.js";
import {Aftersale} from "./Aftersale.js";
import {Delivery} from "./Delivery.js";
import {Paypolicy} from "./Paypolicy.js";
import {Login} from "./login.js";
import {Signup} from "./signup.js";
import { UserInfo } from "./UserInfo.js";
import { UserDetails } from "./UserDetails.js";
import { TransactedOrders } from "./TransactedOrders.js";
import { PendingOrders } from "./PendingOrders.js";
//import { Watchlist } from "./Watchlist.js";
import { ResetPassword } from "./ResetPassword.js";
import { ResetNewPassword } from "./ResetNewPassword.js";
import { ResetResetPassword } from "./ResetResetPassword.js";
import { VerifyResetPassword } from "./VerifyResetPassword.js";
import { useCart } from "./cart-hooks";
import { Checkout } from "./Checkout.js";
//import { SecurePay } from "./SecurePay.js";
import {Stripe} from './Stripe.js';
//import { Pay } from "./Pay.js";
//import {Dashboard} from "./Dashboard.js";
import { Electronic } from "./electronic.js";
//import {AuthRoute} from "AuthRoute.js";
import {Product} from "./Product.js";
//import {Milkpowder} from "./milkpowder.js";
import {Paid} from "./Paid.js";
import {Cart} from "./cart.js";
import { Category } from "./Category.js";
import { Subcategory } from "./Subcategory.js";




function App(props) {

  const {cart} = useCart();

  return (
    <div>
        <Routes>
            <Route path="/" element={<Layout />}>
             <Route index element={<Home />} />
             <Route path='Home' element={<Home />} />
             <Route path='category/:category' element={<Category />} />
             <Route path='category/:category/subcategory/:subcategory' element={<Subcategory />} />
             <Route path='electronic' element={<Electronic />} />
             <Route exact path='/product/:name' element={<Product />} />
             <Route path='login' element={<Login />} />
             <Route path='VerifyEmail' element={<VerifyResetPassword />} />
             <Route path='ResetNewPassword' element={<ResetNewPassword />} />
             <Route path='VerifyResetPassword/:id/:token' element={<VerifyResetPassword />} />
             <Route path='ResetResetPassword/:id/:token' element={<ResetResetPassword />} />
             {props.user.email==='' && (<Route path='signup' element={<Signup />} />)}
             <Route path='cart' element={<Cart />} />
             {cart.length>0 && <Route path='checkout' element={<Checkout />} />}
              {cart.length>0 && <Route path='pay' element={<Stripe />} />}
             {true && (<Route path='userinfo' element={<UserInfo />} >
                <Route path='userDetails' index element={<UserDetails />} />
                <Route path='pendingOrders' element={<PendingOrders />} />
                <Route path='transactedOrders' element={<TransactedOrders />} />
                <Route path='resetPassword' element={<ResetPassword />} />
             </Route>)}
             <Route path='paymentConfirmed' element={<Paid />} />
             <Route path='contact' element={<Contact />} />
             <Route path='aboutus' element={<Aboutus />} />
             <Route path='aftersale' element={<Aftersale />} />
             <Route path='delivery' element={<Delivery />} />
             <Route path='paypolicy' element={<Paypolicy />} />
             
            </Route >
                
        </Routes>
    </div>
  );
}

export default App;
