import React, { createContext, useContext, useEffect, useState } from "react";
import  secureLocalStorage  from  "react-secure-storage";
//import {v4} from "uuid";
//import cartData from "./cart_data.json";

const CartContext = createContext();

export function CartProvider({children}) {

    const loadJSON = key => key && JSON.parse(secureLocalStorage.getItem(key));
    const saveJSON = (key, data) => secureLocalStorage.setItem(key, JSON.stringify(data));

    const localData = loadJSON('cart');

    const [cart, setCart] = useState(localData && localData.length>0 ? localData:[]);
    const [total, setTotal] = useState(0);

    const Total = () => {
        let totalVal = 0;
        if (!cart) {
            return 0;
        };
        cart.forEach(element => 
            
         {
            if (element.quantity>1) {
                totalVal += element.quantity*element.price;
            }
            else {
                totalVal += element.price;
            }
        });
        setTotal(totalVal);
    };

    useEffect(() => {
        Total();
        saveJSON('cart', cart);
        // eslint-disable-next-line
    }, [cart]
    );

    const removeFromCart = (product) => setCart(cart.filter(item => item !== product));
    
    const addToCart = (product) => {
        let NewCart = cart.filter(item => item.id !== product.id);
        let same = false;    
        cart.forEach(element =>  {
               if (element.id===product.id) {
                same = true;
                setCart([...NewCart, {...element, quantity: element.quantity+1}]);
               }
           });
        if (same) return;
        else {
            setCart([...cart, product]);
        }       
}

    const increaseItemNum = (product) => {
        let NewCart = cart.filter(item => item.id !== product.id)
        setCart([...NewCart, {...product, quantity: product.quantity+1}]);
    }

    const reduceFromCart = (product) => {
        if (product.quantity>1) {
            let NewCart = cart.filter(item => item.id !== product.id);
            setCart([...NewCart, {...product, quantity: product.quantity-1}]);
        }
        else if (product.quantity===1) {
            removeFromCart(product);
        }
    }

    return (
        <CartContext.Provider value={{cart, setCart, total, setTotal, addToCart, increaseItemNum, reduceFromCart, removeFromCart }}>
            {children}
        </CartContext.Provider>
    )
}


export const useCart = () => useContext(CartContext);

