import React from "react";
import axios from "axios";
import { Button, Form, Input, } from "antd";
import { useState } from "react";
import  secureLocalStorage  from  "react-secure-storage";

export function ResetPassword () {

    const loadJSON = key => key && JSON.parse(secureLocalStorage.getItem(key));
    const user = loadJSON('user');
    const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL + '/ResetPassword';

    const [Info, setInfo] = useState({
        newPassword: "",
        confirmNewPassword: "",
    });

    const onChangehandler = (e) => {
        e.preventDefault();
        var name = e.target.name;
        var value = e.target.value;
        setInfo(previous => {
        return {...previous, [name]: value};
        });
    };

    const onSubmithandler = (e) => {
        e.preventDefault();
        axios.post(API_URL, Info, {
            Headers: {
                'content-type': 'multipart/form-data',
                'x-access-token': user.accessToken,
            }
        })
        .then(res=>{
            alert('Password Successfully Reset!')
            //console.log(res);
        })
        .catch(err=>{
            alert('Password Reset Failed!')
            //console.log(err);
        });
    };
    
    return (
        <div>
          <h3>Reset Password</h3>
          <Form style={{width: '60%', margin:'auto', marginTop: '3%', marginBottom: '3%'}}>
                <Form.Item label='New Password' style={{}}>
                    <Input style={{borderRadius:'9px'}} type="text"
                           name="height"
                           value={Info.newPassword}
                           onChange={onChangehandler}/>
                  </Form.Item>
                  <Form.Item label='Confirm' style={{}}>
                    <Input style={{borderRadius:'9px'}} type="text"
                           name="weight"
                           value={Info.confirmNewPassword}
                           onChange={onChangehandler}/>
                  </Form.Item> 
            </Form>
            <Button onClick={onSubmithandler} type="primary" shape="round" size="medium"
                      >Submit</Button>
        </div>
    )
}