import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Navigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

const UserContext = createContext();

export function UserProvider({children}) {
    /*
    function loadJSON (key) {
        return JSON.parse(secureLocalStorage.getItem(key));
    };*/

    const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;
    //const loadJSON = (key) => key && JSON.parse(secureLocalStorage.getItem(key));
    const saveJSON = (key, data) => secureLocalStorage.setItem(key, JSON.stringify(data));
    const localUser = JSON.parse(secureLocalStorage.getItem(('user')));
    const localuserInfo = JSON.parse(secureLocalStorage.getItem(('userInfo')));
    //const localdashBoard = loadJSON('dashBoard');

    //const localWatchlist = loadJSON('localWatchlist');
    //const [watchlist, setWatchlist] = useState(localWatchlist && localWatchlist.length>0 ? localWatchlist:[]);
    const [userInfo, setUserInfo] = useState(JSON.parse(localuserInfo));

    //const [dashBoard, setDashboard] = useState(JSON.parse(localdashBoard));

    //const [watchlist, setWatchlist] = useState([]);

    const [user, setUser] = useState(localUser!==null ? localUser : {
        email: '',
        roles: [],
        accessToken: ''
      });

    useEffect(() => {
        if (user) {
            setUserInfo(user.roles.includes("user"));
            saveJSON('userInfo', user.roles.includes("user"));
            //setDashboard(user.roles.includes('user'));
            //saveJSON('dashBoard', user.roles.includes('user'));
            //setUser(user);
            saveJSON('user', user);
            

        };
        /*
        if (watchlist) {
        setWatchlist(watchlist);
        console.log('watchlist useEffect()...');
        saveJSON('localWatchlist', watchlist);
        }*/
      // eslint-disable-next-line
    }, [user]
    );
    /*
    useEffect(() => {
        //setWatchlist(watchlist);
        saveJSON('localWatchlist', watchlist);
        
    }, [watchlist]);
    */
    const login = (username, password) => {
        return axios.post(API_URL+"login", {username, password})
                    .then(response => {
                        if (response.data.accessToken) {
                            setUser(response.data);
                        }
                        return response.data;
                    })
    };

    const logout = () => {
        secureLocalStorage.removeItem("user");
        secureLocalStorage.removeItem("userInfo");
        //secureLocalStorage.removeItem("dashBoard");
        setUser({
            email: '',
            roles: [],
            accessToken: '',
        });
        saveJSON('user', {
            email: '',
            roles: [],
            accessToken: '',
        });
        setUserInfo(false);
        saveJSON('userInfo', false);
        //setDashboard(false);
        //saveJSON('dashBoard', false);

        saveJSON('redirect', false);

        return <Navigate to='Home' />;
    }
    /*
    const getCurrentUser = () => {
        return JSON.parse(secureLocalStorage.getItem(("user")));
    }
    
    const authHeader = () => {
        const user = getCurrentUser();
        if (user.email && user.accessToken) {
            return {'x-access-token': user.accessToken};
        }
        else {
            return {};
        }
    };

    const removeFromWatchlist = (subject) => {
        setWatchlist(watchlist.filter(item => item.id !== subject.id));
        axios.post(API_URL + "/removefromMyWatchlist", {user: user, 
                                                        list: watchlist},
            {
             headers:{"Content-Type": "application/json"},
             withCredentials:false,
            })
            .then((response) => {
                const products = response.data;
                console.log('remove from my watchlist: '+products);
                //setWatchlist(products);
                saveJSON('localWatchlist', products);
            });
    };
    
    const addToWatchlist = (subject) => {
        //console.log('addtoUserWatchlist...');
        //let NewCart = watchlist.filter(item => item._id !== subject._id);
        let same = false; 
        if (watchlist) { 
        watchlist.forEach(element =>  {
               if (element.id===subject.id) {
                same = true;
               }
           });
        };
        if (same) return;
        else {
            var insert = [...watchlist, subject];
            setWatchlist(insert);
            saveJSON('localWatchlist', insert);
            axios.post(API_URL + "/addtoMyWatchlist", {user: user, 
                                                       list: insert},
                {
                 headers:{"Content-Type": "application/json"},
                 withCredentials:false,
                })
                .then((response) => {
                    const product_list = response.data;
                    console.log('insert my watchlist: '+JSON.stringify(product_list));
                    //setWatchlist(product_list);
                    //saveJSON('localWatchlist', product_list);
            });
        }       
    };*/

    return (
        <UserContext.Provider value={{userInfo, user, login, logout, setUser, setUserInfo}}>
            {children}
        </UserContext.Provider>
    )
}


export const useUser = () => useContext(UserContext);