import React from "react";
//import Button from 'react-bootstrap/Button';
//import Form from 'react-bootstrap/Form';
import axios from "axios";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { Button, Form, Input, Result, Alert } from "antd";
import { Link } from 'react-router-dom';
import { useState } from "react";
import {useNavigate} from 'react-router-dom';
import {useUser} from "./user-hooks.js";
import  secureLocalStorage  from  "react-secure-storage";
import "antd";
import "./login.css";


export function Login() {

    const {setUser} = useUser();

    const loadJSON = key => key && JSON.parse(secureLocalStorage.getItem(key));
    const saveJSON = (key, data) => secureLocalStorage.setItem(key, JSON.stringify(data));

    saveJSON('redirect', false);

    const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;

    const [info, setInfo] = useState({
        email: "",
        password: "",
        msg: "",
        isLoading: false,
        redirect: false,
        errMsgEmail: "",
        errMsgPwd: "",
        errMsg: "",
    });

    const [msg, setMsg] = useState('');

    const onChangehandler = (e) => {
        e.preventDefault();
        var name = e.target.name;
        var value = e.target.value;
        setInfo(previous => {
            return {...previous, [name]: value};
        });
    };

    const navigate = useNavigate();

    const onForgetPasswordhandler = (e) => {
        e.preventDefault();
        navigate("/VerifyEmail", {from: "login"});
    };

    const onSubmithandler = (e) => {
        e.preventDefault();
        if (info.email==="" || info.password==="") {
            setMsg('Please enter your email or password.');
            return;
        }
        axios.post(API_URL + "/login", JSON.stringify({
            email: info.email,
            password: info.password,
        }),
        {
            headers:{"Content-Type": "application/json"},
            withCredentials:false,
        })
        .then((response) => {
            
            //setInfo({isLoading: true});
            if (response.status === 200) {
                console.log(response);
                setInfo({redirect: true});
                setUser(response.data);
                saveJSON('user', response.data);
                //setUserInfo(response.data.roles.includes('user'));
                //setDashboard(response.data.roles.includes('user')); 
            };
        })
        .catch((error) => {
            //console.log(error);
            setMsg('Account or Password is incorrect!');
        });
    }
      
    if (info.redirect||loadJSON('userInfo')) {
        
        setTimeout(() => {
            navigate('/Home');
        }, 1500);

        return (
        <div className="aboutus">
          <Result className="containers"
            status="success"
            title="Successfully Logged In!"
            subTitle=""
            extra={[
              <Button type="primary" key="console">
                <Link to='/Home'>Go to Home Page </Link>
              </Button>,
            ]}
          />
        </div>);}


    return (
        <div className="login">
            <Form className="containers">
            <Form.Item label='Email Address' name="email" rules={[{required: true, message:'Please enter your email'}]}
            >
                <Input type="email"
                       name="email"
                       value={info.email}
                       onChange={onChangehandler} />    
               </Form.Item>
               <span className="text-alert">{info.msg}</span>
               <span className="text-alert">{info.errMsgEmail}</span>     
               <Form.Item label='Password' name="password" rules={[{required: true, message:'Please enter your password'}]}>
                <Input.Password type="password"
                       name="password"
                       value={info.password}
                       onChange={onChangehandler}
                       iconRender={(visible) => (visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />)}
                         style={{width:'100%'}} />
               </Form.Item>   
               {msg===""? (<div></div>): <Alert message={msg} 
                                             type='warning' 
                                             style={{margin: 'auto', marginTop:'1%', marginBottom:'1%'}} showIcon closable/>}
               <Button onClick={onForgetPasswordhandler} type="text" shape="round" size="small"
                      >Forget Password ?</Button>
               <Button type="primary" shape="round" size="large"
                        onClick={onSubmithandler}>Login</Button>
            </Form>
        </div>
    )
}