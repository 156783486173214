import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Layout, Button, Dropdown } from "antd";
import { AiOutlineLogin, AiOutlineLogout, AiOutlineUser, AiOutlineMenu, AiOutlineShoppingCart } from 'react-icons/ai';
import "antd";
import "./Pheader.css";
import { useUser } from './user-hooks.js';


export default function Pheader(props) {
    /*
    const loadJSON = key => key && JSON.parse(localStorage.getItem(key));
    const userInfo = loadJSON('userInfo');
    const dashBoard = loadJSON('dashBoard');
    */
    const {logout} = useUser();
    const {Header} = Layout;
    //const loadJSON = key => key && JSON.parse(localStorage.getItem(key));
    
    //const {Search} = Input;
    const items = [
        {
        key: '1',
        label: (<Link to="/">Home</Link>),
        },
        {
        key: '2',
        label: (<Link to="category/electronics" state={'electronics'}>Electronics</Link>),
        children: [
                {
                   key: '2-1',
                   label: (<Link to="category/electronics/subcategory/smartphones" state={{category: 'electronics', subcategory: 'smartphones'}}>smart phones</Link>),
                },
                {
                   key: '2-2',
                   label: (<Link to="category/electronics/subcategory/laptops" state={{category: 'electronics', subcategory: 'laptops'}}>laptops</Link>),
                },
            ],
        },
        {
            key: '3',
            label: (<Link to="category/electrical&homeappliances" state={'electrical&homeappliances'}>Electrical & Home Appliances</Link>),
            children: [
                    {
                       key: '3-1',
                       label: (<Link to="category/electrical&homeappliances/subcategory/smartrobots" state={'electrical&homeappliances'}>smart robots</Link>),
                    },
                    {
                       key: '3-2',
                       label: (<Link to="category/electrical&homeappliances/subcategory/fancoolers" state={'electrical&homeappliances'}>fan coolers</Link>),
                    },
                ],
            },
        {
        key: '4',
        label: (<Link to="category/beauty&personalcare" state={'beauty&personalcare'}>Beauty & Personal Care</Link>),
        children: [
                {
                   key: '4-1',
                   label: (<Link to="category/beauty&personalcare/subcategory/lipsticks" state={'beauty&personalcare'}>lipsticks</Link>),
                },
                {
                   key: '4-2',
                   label: (<Link to="category/beauty&personalcare/subcategory/eyecreams" state={'beauty&personalcare'}>eye creams</Link>),
                },
            ],
        },
        {
        key: '5',
        label: (<Link to="category/furniture&living" state={'furniture&living'}>Furniture & Living</Link>),
        children: [
                {
                   key: '5-1',
                   label: (<Link to="category/furniture&living/subcategory/furniture" state={'furniture&living'}>furniture</Link>),
                },
                {
                   key: '5-2',
                   label: (<Link to="category/furniture&living/subcategory/decorations" state={'furniture&living'}>decorations</Link>),
                },
            ],
        },
        {
            key: '6',
            label: (<Link to="category/sports&outdoors" state={'sports&outdoors'}>Sports & Outdoors</Link>),
            children: [
                    {
                       key: '6-1',
                       label: (<Link to="category/sports&outdoors/subcategory/sports" state={'sports&outdoors'}>sports</Link>),
                    },
                    {
                       key: '6-2',
                       label: (<Link to="category/sports&outdoors/subcategory/outdoors" state={'sports&outdoors'}>outdoors</Link>),
                    },
                ],
            },
            {
                key: '7',
                label: (<Link to="category/agedcare" state={'agedcare'}>Aged Care</Link>),
                children: [
                        {
                           key: '7-1',
                           label: (<Link to="category/agedcare/subcategory/" state={'agedcare'}>tools</Link>),
                        },
                        {
                           key: '7-2',
                           label: (<Link to="category/agedcare/subcategory/" state={'agedcare'}>tools</Link>),
                        },
                    ],
                },
            {
                key: '8',
                label: (<Link to="category/automotive&tools" state={'automotive&tools'}>Automotive & Tools</Link>),
                children: [
                        {
                           key: '8-1',
                           label: (<Link to="category/automotive&tools/subcategory/accessories" state={'automotive&tools'}>accessories</Link>),
                        },
                        {
                           key: '8-2',
                           label: (<Link to="category/automotive&tools/subcategory/tools" state={'automotive&tools'}>tools</Link>),
                        },
                    ],
                },
    ];

    return (
        <Header className="header" style={{width:'100%', backgroundColor: 'white', height:"25%"}}>

            <div className='navbar'>
              <Dropdown menu={{items}}>
                <a href="/#" onClick={(e) => e.preventDefault()}>  
                   <AiOutlineMenu />
                </a>
              </Dropdown>
            </div>
            <span key='header' style={{marginLeft:'1%'}} > Shop </span>
            
            
            
            <div className='shortcut'>
                <Menu mode='horizontal'>
                  {props.user.accessToken!=='' ? <Menu.Item key='login'><Button onClick={() => logout()} type='link'><AiOutlineLogout /> Logout </Button></Menu.Item>: <Menu.Item><Link to="login"><AiOutlineLogin/> Login </Link></Menu.Item>}
                  
                  {props.user.accessToken==='' && (<Menu.Item key='signup'><Link to="signup"><AiOutlineUser/> Signup </Link></Menu.Item>)}
                  
                  {props.user.accessToken!=='' && <Menu.Item key='userInfo'><Link to="userInfo"> Account </Link></Menu.Item>}
                  
                  <Menu.Item key='cart'><Link to="cart"><AiOutlineShoppingCart/> Cart</Link></Menu.Item>
                 
                </Menu>
            </div>
            
        </Header>
    )
}