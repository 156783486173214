import React, { useEffect, useState } from "react";
import  secureLocalStorage  from  "react-secure-storage";
import { Card, Divider, Button, Modal, Form, Rate, Input, } from 'antd';
//import "./TransactedOrders.css";
import axios from "axios";

export function TransactedOrders () {

    const loadJSON = key => key && JSON.parse(secureLocalStorage.getItem(key));
    const saveJSON = (key, data) => secureLocalStorage.setItem(key, JSON.stringify(data));
    const user = loadJSON('user');
    const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;
    const localTransactedOrders = loadJSON('localTransactedOrders');
    const [transactedOrders, setTransactedOrders] = useState(localTransactedOrders && localTransactedOrders.length>0 ? localTransactedOrders:[]);

    useEffect(() => {
      axios.post(API_URL + "/deliveredOrders", JSON.stringify(user),
      {
          headers:{"Content-Type": "application/json"},
          withCredentials:false,
      })
      .then((response) => {
          //console.log('history orders: '+JSON.stringify(response.data));
          const orders = response.data;
          setTransactedOrders(orders);
          saveJSON('localTransactedOrders', orders);
    })}, []);

    const [open, setOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOk = () => {
      setIsModalOpen(false);
    };

    const onRate = (values, prod) => {
      //console.log('rate form values: '+JSON.stringify(values));
      const product = prod;
      const rating = {...values, user: user, product: product};
      //console.log('rating: '+JSON.stringify(rating));

      axios.post(API_URL + "/rate", rating,
      {
          headers:{"Content-Type": "application/json"},
          withCredentials:false,
      });

      setOpen(false);
      return Modal.success({
        
        content: (
          <div>
            <p>Thank you for your rating.</p>
          </div>
        ),
        onOk() {},
      });
    };

    const onReturnhandler = (order, e) => {
      //e.preventDefault();
      return Modal.confirm({
        title: 'Return & Refund: ',
        okText: 'Confirm',
        cancelText: 'Cancel',
        content: (
          <div>
            <p>Are you sure to return order and get refunded?</p>
          </div>
        ),
        onOk() {
          axios.post(API_URL + "/cancelOrder", JSON.stringify(order),
          {
              headers:{"Content-Type": "application/json"},
              withCredentials:false,
          })
          .then((response) => {
            //console.log('transacted order: '+JSON.stringify(response.data));
            let NewOrderlist = transactedOrders.filter(item => item.id !== order.id);
            //console.log('new order list: '+JSON.stringify(NewOrderlist));
            let updatedOrder = response.data;
            setTransactedOrders({...NewOrderlist, updatedOrder});
          });
        },
      });
    };

    const {TextArea} = Input;

    const RateModalForm = ({ open, onRate, item }) => {
        const [form] = Form.useForm();
        const prod = item;
        return (
            <Modal mask={false}
                   maskStyle={{background:'transparent'}}
                   open={open}
                   title='Please rate the product.'
                   okText='Submit'
                   cancelText='Cancel'
                   onCancel={() => {setOpen(false);}}
                   onOk={() => {
                     form.validateFields()
                         .then((values) => {
                           onRate(values, prod);
                           form.resetFields();
                         })
                   }} >
                <Form form={form}
                      layout="vertical">
                    <Form.Item name="rate" label="Overall Rating">
                        <Rate allowHalf/>
                    </Form.Item>
                    <Form.Item name="title" label="Review Title"
                               rules={[
                                      {
                                       required: true,
                                       message: 'Example: Easy to use',
                                      },
                                ]}>
                        <Input placeholder="Example: Easy to use"/>
                    </Form.Item>
                    <Form.Item name="review" label="Product Review">
                        <TextArea type="textarea" />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };


    return (
      transactedOrders.length!==0 && Array.isArray(transactedOrders) ? (
        <div>
          <h3>Delivered Orders</h3>
          {transactedOrders.map((order) => (
            <Card key={order.id} style={{height: '2%', margin:'auto', marginTop: '2%',marginBottom: '5%',borderRadius:'5px', boxShadow:'0 4px 8px 0 rgba(201, 189, 232, 0.2)'}}>
            <div className="orderStatus">
              <p style={{textAlign:'left'}} key='date'>Date: {order.date}</p>
              <p style={{textAlign:'left'}} key='status'>Order ID: {order.id}</p>
              <p style={{textAlign:'left'}} key='price'>Status: {order.status}</p>
            </div>
            <Divider/>
            <div>
              { order.items.map(item => 
              (<div className="infoitemtable" key={item.id} style={{display:'flex', justifyContent:'space-between', marginBottom:'2%'}}>
                <img alt={item.id} className="info-product-img" src={API_URL+'/uploads/'+item.imgs.icons[0]} />
                <div style={{wordWrap:'break-word', maxWidth:'40%'}}>
                  <div>
                    {item.name}
                  </div>
                  <div style={{wordWrap:'break-word'}}>
                    {item.description}
                    {!Object.is(item.selections, {}) && Object.entries(item.selections).map(feature => feature[1]) }
                  </div>
                  <div>
                    $ {item.price}
                  </div>
                </div>
                <div>
                    Quantity: {item.quantity}
                </div>
                <div>
                    <Button className="rateButton" onClick={()=>{setOpen(true);}} type="link">Rate</Button>
                    <RateModalForm open={open}
                                   item={item}
                                   onRate={onRate}
                                   onCancel={() => {
                                    setOpen(false);
                                   }} />
                </div>
              </div>))}
            </div>
            <Divider/>
            <div style={{display:'flex', justifyContent:'space-between'}}>
              <div>Total: $ {order.total/100}</div>
              <div><Button onClick={() => onReturnhandler(order)}>Cancel Order</Button></div>
              <Modal open={isModalOpen} onOk={handleOk}>
                   <p>You have canceled the order.</p>
              </Modal>
            </div>
          </Card>))}
        </div>): (<div style={{margin:'auto', marginTop:'25vh'}}>
                    <div>
                        No History Orders Yet.
                    </div>
                </div>)
    );
}