import React, { useState, useEffect } from "react";
import {ProductGrid} from './ProductGrid.js';
import axios from "axios";
import {useLocation} from 'react-router-dom';
import "./productGrid.css";

export function Category() {

    const [catalogue, setCatalogue] = useState({});
    const locat = useLocation();
    const category = locat.state;
    const currentUrl = locat.pathname;
    const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;
    //const {addToCart} = useCart();
    //const electronics = useState(electronic);
    const url = API_URL + currentUrl;
    //console.log('url: '+url);
    
    useEffect(() => {
      axios.post(url, {category: category},
      {
        headers:{"Content-Type": "application/json"},
        withCredentials:false,
      })
         .then(response => {
            setCatalogue(response.data);
            //setIsloading(false);
            //console.log('category response data: ' + JSON.stringify(response.data));
         });
    }, []);

    return (
      catalogue.length!==0 && Array.isArray(catalogue) ? (
      <div>
          <div>
          <ProductGrid catalogue={catalogue} />
          </div>
      </div>
      ) : (
           <div>
                  <div style={{margin:'auto', marginTop:'25vh'}}>
                        No items found...
                  </div>
            </div>)

    )
}