import React from "react";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";

export function Paid() {
    //alert('Your payment is successful!');
    return (<Result
        status="success"
        title="Your payment is Successful!"
        subTitle=""
        extra={[
          <Button type="primary" key="console">
            <Link to='/userInfo/pendingOrders'>Check for pending orders</Link>
          </Button>,
        ]}
      />);
}