import React, { useEffect, useState }from "react";
import axios from "axios";
import { Spin, Space} from "antd";
import { useLocation } from "react-router-dom";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {Payment} from "./Payment.js";
import  secureLocalStorage  from  "react-secure-storage";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
//const options = {clientSecret: ""}

//const loader = 'auto';

export function Stripe () {

    const loadJSON = key => key && JSON.parse(secureLocalStorage.getItem(key));
    const saveJSON = (key, data) => secureLocalStorage.setItem(key, JSON.stringify(data));
    const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;

    const [clientSecret, setClientSecret] = useState("");
    const appearance = {theme:'stripe'};

    const location = useLocation();
    const order = location.state===null? loadJSON('order'):location.state;
    const [paidOrder, setPaidOrder] = useState({});
    const [isLoading, setIsloading] = useState(true);
    saveJSON('order', order);
    //console.log('stripe order: '+JSON.stringify(order));
    var ord = {};

    useEffect(() => {
        axios.post(API_URL+"/create-payment-intent", 
                   JSON.stringify(order),
            {headers: { "Content-Type": "application/json" }}
            
        )
        .then((res) => {
            console.log(res.data.clientSecret);
            setClientSecret(res.data.clientSecret);
            ord = {...order, paymentIntentID: res.data.paymentIntentID};
            setPaidOrder(ord);
            saveJSON('order', ord);
            setIsloading(false);
            //console.log('payIntentID order: '+JSON.stringify(ord));
        });
            
        }, []);
    
    
    const options = {clientSecret: clientSecret, appearance};
    //console.log('options: '+JSON.stringify(options));

    return ( 
        isLoading? (
        <Space direction='vertical' style={{height:'380px', marginTop:'20%', width:'100%'}} >
            <Spin tip="Loading..." size="large">
               <div className="content" />
            </Spin>
        </Space>):
        (clientSecret && stripePromise &&
        (<Elements stripe={stripePromise} options={options}>
            <Payment order={paidOrder}/>
        </Elements>))
    )
}