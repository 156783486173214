import React from "react";
import {Layout, Menu} from 'antd';
import { Outlet, Link } from "react-router-dom";
import  secureLocalStorage  from  "react-secure-storage";
//import { UserProvider } from './user-hooks.js';

export function UserInfo () {
    const loadJSON = key => key && JSON.parse(secureLocalStorage.getItem(key));
    const {Content, Sider} = Layout;
    const user = loadJSON('user');
    return (
        <Layout className="infolayout">
            <Sider width={'20vw'} style={{height:'83vh', background:(255, 255, 255), paddingTop: '2%', borderRadius: '8px'}}>
                <Menu>
                    <Menu.Item style={{fontSize:'x-small'}} key='userDetails'><Link to='userDetails' state={user}>Account</Link></Menu.Item>
                    <Menu.Item style={{fontSize:'x-small'}} key='pendingOrders'><Link to='pendingOrders' state={user}>Pending</Link></Menu.Item>
                    <Menu.Item style={{fontSize:'x-small'}} key='transactedOrders'><Link to='transactedOrders' state={user}>Delivered</Link></Menu.Item>
                    <Menu.Item style={{fontSize:'x-small'}} key='resetPassword'><Link to='resetPassword' state={user}>Reset</Link></Menu.Item>
                </Menu>
            </Sider>
            <Layout>
              <Content style={{padding: '1%'}}>
                <div style={{paddingTop: '2%', paddingLeft: '1%', paddingRight: '1%', paddingBottom:'2%', backgroundColor: 'white', minHeight:'100%', borderRadius: '4px'}}>
                  <Outlet />
                </div>
              </Content>
            </Layout>
        </Layout>
    );
}