import React, { useState, useEffect } from "react";
import {Form, Input, Button, Divider} from 'antd';
import { useNavigate } from "react-router-dom";
import "./checkout.css";
//import { Link } from 'react-router-dom';
//import axios from "axios";
import { useCart } from "./cart-hooks";
import { useUser } from './user-hooks.js';
import { CartSummary } from "./CartSummary";
import  secureLocalStorage  from  "react-secure-storage";


export function Checkout () {
    
    //const loadJSON = key => key && JSON.parse(secureLocalStorage.getItem(key));
    const saveJSON = (key, data) => secureLocalStorage.setItem(key, JSON.stringify(data));

    //const localData = loadJSON('order');

    const {cart, total} = useCart();
    const {user} = useUser();

    const timestamp = new Date();
    const items_val = total.toFixed(2);
    const tax_val = (total/11).toFixed(2);
    const freight_val = 10;
    const total_val = Math.round(total + 10);

    const [order, setOrder] = useState({
      id: timestamp + user.id,
      customerID: user.id,
      paymentIntentID: "", 
      paymentStatus: 'unpaid',
      status: 'paid',
      status_code: 0,
      currency: 'aud', 
      carrier: 'AUPost',
      items: cart, 
      date: timestamp,
      Customer: {
        FirstName: "",
        LastName: "",
        Street: "",
        City: "",
        State: "",
        PostalCode: "",
        Country: "",
        Mobile: "",
        Email: "",
     },
      ShippingAddress: {
        FirstName: "",
        LastName: "",
        Street: "",
        City: "",
        State: "",
        Country: "",
        PostalCode: "",
        Phone: ""
     },
      itemsTotal: items_val,
      tax: tax_val,
      freight: freight_val,
      total: total_val*100,
    });

    useEffect(() => {
      //setOrder(order);
      saveJSON('order', order);
      // eslint-disable-next-line
      }, [order]
    );

    const navigate = useNavigate();

    /*
    const onChangehandler = (e) => {
      e.preventDefault();
      var name = e.target.name;
      var value = e.target.value;
      setOrder(previous => {
        return {...previous, [name]: value};
      });
    };*/

    const onCustomerChangehandler = (e) => {
      e.preventDefault();
      var name = e.target.name;
      var value = e.target.value;
      setOrder(previous => {
        return {...previous, 
                Customer: {...previous.Customer, [name]: value}
        }
      });
    };

    const onShippingChangehandler = (e) => {
      e.preventDefault();
      var name = e.target.name;
      var value = e.target.value;
      setOrder(previous => {
        return {...previous, 
                ShippingAddress: {...previous.ShippingAddress, [name]: value}
        }
      });
    };

    const onSubmithandler = (e) => {
        e.preventDefault();

        navigate("/pay", {from: "checkout", state: order});  
    };

    return (
        <div className="container">
          <div className='upperContainer'>
          <h1 className="checkout">Checkout</h1>
          <div className="shippingSummary">
            <div>
              <div className="customerInfoContainer">
              <h2 className="customerInfo">Customer Info</h2>
              <Form layout='inline'>
                  <Form.Item label='First Name'>
                    <Input style={{borderRadius:'9px'}} type="text"
                           name="FirstName"
                           value={order.Customer.FirstName}
                           onChange={onCustomerChangehandler} />
                  </Form.Item>
                  <Form.Item label='Last Name'>
                    <Input style={{borderRadius:'9px'}} type="text"
                           name="LastName"
                           value={order.Customer.LastName}
                           onChange={onCustomerChangehandler}/>
                  </Form.Item>
                  <Form.Item label='Email Address'>
                    <Input style={{borderRadius:'9px'}} type="text"
                           name="Email"
                           value={order.Customer.Email}
                           onChange={onCustomerChangehandler}/>
                  </Form.Item>
                  </Form>
                <Form layout='inline' style={{width: '100%', marginTop: '3%', marginBottom: '3%'}}>
                  <Form.Item style={{width: '64%'}} label='Street Address'>
                  <Input style={{borderRadius:'9px'}} type="text"
                         name="Street"
                         value={order.Customer.Street}
                         onChange={onCustomerChangehandler}/>
                  </Form.Item>
                  <Form.Item label='Postal Code'>
                    <Input style={{borderRadius:'9px'}} type="text"
                           name="PostalCode"
                           value={order.Customer.PostalCode}
                           onChange={onCustomerChangehandler}/>
                  </Form.Item>
                </Form>
                <Form layout='inline'>
                  <Form.Item label='Town/City'>
                    <Input style={{borderRadius:'9px'}} type="text"
                           name="City"
                           value={order.Customer.City}
                           onChange={onCustomerChangehandler}/>
                  </Form.Item>
                  <Form.Item label='State'>
                    <Input style={{borderRadius:'9px'}} type="text"
                           name="State"
                           value={order.Customer.State}
                           onChange={onCustomerChangehandler}/>
                  </Form.Item>
                  <Form.Item label='Country'>
                    <Input style={{borderRadius:'9px'}} type="text"
                           name="Country"
                           value={order.Customer.Country}
                           onChange={onCustomerChangehandler}/>
                  </Form.Item>
                </Form>
                <Form layout='inline' style={{width: '100%', marginTop: '3%', marginBottom: '3%'}}>
                  <Form.Item label='Contact Number'>
                  <Input style={{borderRadius:'9px'}} type="text"
                         name="Mobile"
                         value={order.Customer.Mobile}
                         onChange={onCustomerChangehandler}/>
                </Form.Item>
                </Form>
                </div>
              <div className="shippingDetailsContainer">
              <h2 className="shippingDetails">Shipping Details</h2>
              
                <Form layout='inline'>
                  <Form.Item label='First Name' rules={[{required: true, message:'Please enter recipient\'s first name.'}]}>
                    <Input style={{borderRadius:'9px'}} type="text"
                           name="FirstName"
                           value={order.ShippingAddress.FirstName}
                           onChange={onShippingChangehandler}/>
                  </Form.Item>
                  <Form.Item label='Last Name' rules={[{required: true, message:'Please enter recipient\'s last name.'}]}>
                    <Input style={{borderRadius:'9px'}} type="text"
                           name="LastName"
                           value={order.ShippingAddress.LastName}
                           onChange={onShippingChangehandler}/>
                  </Form.Item>
                  <Form.Item label='Contact Number' rules={[{required: true, message:'Please enter recipient\'s contact number.'}]}>
                  <Input style={{borderRadius:'9px'}} type="text"
                         name="Phone"
                         value={order.ShippingAddress.Phone}
                         onChange={onShippingChangehandler}/>
                </Form.Item>
                </Form>
                  <Form layout='inline' style={{width: '100%', marginTop: '3%', marginBottom: '3%'}}>
                    <Form.Item style={{width: '64%'}} label='Street Address' rules={[{required: true, message:'Please enter recipient\'s street address.'}]}>
                      <Input style={{borderRadius:'9px'}} type="text"
                         name="Street"
                         value={order.ShippingAddress.Street}
                         onChange={onShippingChangehandler}/>
                    </Form.Item>
                    <Form.Item label='Postal Code' rules={[{required: true, message:'Please enter recipient\'s postal code.'}]}>
                    <Input style={{borderRadius:'9px'}} type="text"
                           name="PostalCode"
                           value={order.ShippingAddress.PostalCode}
                           onChange={onShippingChangehandler}/>
                  </Form.Item>
                  </Form>
                
                 <Form layout="inline">
                 <Form.Item label='Town/City' rules={[{required: true, message:'Please enter recipient\'s town or city.'}]}>
                    <Input style={{borderRadius:'9px'}} type="text"
                           name="City"
                           value={order.ShippingAddress.City}
                           onChange={onShippingChangehandler}/>
                  </Form.Item>
                 <Form.Item label='State' rules={[{required: true, message:'Please enter recipient\'s state.'}]}>
                    <Input style={{borderRadius:'9px'}} type="text"
                           name="State"
                           value={order.ShippingAddress.State}
                           onChange={onShippingChangehandler}/>
                  </Form.Item>
                  <Form.Item label='Country'>
                    <Input style={{borderRadius:'9px'}} type="text"
                           name="Country"
                           value={order.ShippingAddress.Country}
                           onChange={onShippingChangehandler}/>
                  </Form.Item>
                </Form>
                </div>
              </div>
          </div>
          
          <div className="billSummary">
              <h2>Order Summary</h2>
              <div className="tableContainer">
                <table className='cartSumTable'>
                <thead>
                  <tr>
                    <td><h4>Product</h4></td>
                    <td><h4>Qty</h4></td>
                    <td><h4>Price</h4></td>
                  </tr>
                </thead>
                <tbody>
                {
                 (      
                  cart.map((item, i) => (<CartSummary key={i} item={item} />))  
                 )}    
                </tbody>         
                <tfoot>
                  <tr className="subtotal">
                    <td colSpan='6' align='right'><h3>Subtotal : $ {items_val}</h3></td>
                  </tr>
                  <tr className="gst">
                    <td colSpan='6' align='right'><h3>GST (inclusive) : $ {tax_val}</h3></td>
                  </tr>
                  <tr className="shippingCost">
                    <td colSpan='6' align='right'><h3>Shipping Cost : $ {freight_val}</h3></td>
                  </tr>
                  <tr className="total">
                    <td colSpan='6' align='right'><h2>Total :  $ {total_val}</h2></td>
                  </tr>
                </tfoot>
                </table>
              </div>
          </div>
        </div>

        <div className='downContainer'>
            <div className="dvd">
               <Divider />
            </div>
            < div className="Btn">
              <div className="back">  
                <Button style={{backgroundColor:'gray', borderColor:'gray'}} onClick={()=> navigate("/cart", {from: "checkout"})} type="primary" shape="round" size="large"
                      >Back</Button>
              </div>
              <div className="confirm">
                  <Button onClick={onSubmithandler} type="primary" shape="round" size="large"
                      >Confirm</Button>
              </div>  
            </div>
        </div>  

      </div>
    );
}