import React from "react";
import "./Aboutus.css";

export function Aboutus () {
    return (
        <div className="aboutus">
          <div className="aboutcontent">
          <h1>QualVal</h1>
          <p>By QualVal, we bring Quality and Value to our customers.</p>
          <h3>QualVal is an e-commerce start-up company.
          </h3>
          <h4>We are committed to provide a 
             new access to quality and valuable products and services throughout the world
             to customers in Australia.</h4>
          </div>
        </div>
    );
}