import React, { useState, useEffect } from "react";
import {ProductGrid} from './ProductGrid.js';
import axios from "axios";
import {useLocation} from 'react-router-dom';

export function Subcategory() {

    const [catalogue, setCatalogue] = useState({});
    const locat = useLocation();
    const category = locat.state.category;
    const subcategory = locat.state.subcategory;
    const currentUrl = locat.pathname;
    const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;
    const url = API_URL + currentUrl;
    //console.log('url: '+url);
    //const search = window.location.search;
    //const params = new URLSearchParams(search);
    //const subcat = params.get('subcategory');
    //console.log('search: ' + search);
    //console.log('params: ' + params);
    //console.log('category: ' + category);

    var items = []; 

    useEffect(() => {
      //console.log('currentUrl: ' + url);
      axios.post(url, {category: category},
      {
        headers:{"Content-Type": "application/json"},
        withCredentials:false,
      })
         .then(response => { 
            var catlog = response.data;
            items = catlog.filter(item => item.subcategory === subcategory);
            //setIsloading(false);
            //console.log('response data: ' + JSON.stringify(response.data));
            //console.log('filtered items: ' + JSON.stringify(items));
            setCatalogue(items);
         })
    }, []);

    return (
      catalogue.length!==0 && Array.isArray(catalogue) ? (
        <div>
            <div>
            <ProductGrid catalogue={catalogue} />
            </div>
        </div>
        ) : (
             <div>
                    <div style={{margin:'auto', marginTop:'25vh'}}>
                          No items found...
                    </div>
              </div>)
    )
}