import React from "react";
import { Link } from 'react-router-dom';
import {Layout} from "antd";
import "antd";
import "./Pfooter.css";

export default function Pfooter() {
    const {Footer} = Layout;
    return (
        <div id="footer">
          <Footer  style={{position:'-webkit-sticky', bottom:'0rem'}}>
            <div>
            <ul style={{}}>
                <li><span><Link to="aboutus"> About us </Link> </span></li>
                <li><span><Link to="delivery"> Delivery </Link> </span></li>
                <li><span><Link to="paypolicy"> Payment </Link> </span></li>
                <li><span><Link to="aftersale"> After sale </Link></span></li>
                <li><span><Link to="contact"> Contact </Link>   </span></li>
            </ul>
            </div>
            <div style={{marginTop:'1%'}}>
            <p>copyright&copy;2024 All Rights Reserved.</p>
            </div>
          </Footer>
        </div>
    )
}