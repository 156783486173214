import React from "react";
import axios from "axios";
import { Alert, Button, Form, Input, Spin, Space} from "antd";
import { useState, useEffect } from "react";
import {useLocation} from 'react-router-dom';

export function ResetResetPassword () {
  const [Info, setInfo] = useState({
    newPassword: "",
    confirmNewPassword: "",
    isTokenValid: false,
});

const [msg, setMsg] = useState('');
const [isLoading, setIsloading] = useState(true);

const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;
const locat = useLocation();
const currentUrl = locat.pathname;

useEffect(() => {
    //console.log('currentUrl: ' + API_URL + currentUrl);
    axios.get(API_URL + currentUrl,
    {
        headers:{"Content-Type": "application/json"},
        withCredentials:false,
    })
         .then(response => {
            setInfo({isTokenValid: response.data.isTokenValid});
            setIsloading(false);
            //console.log('response data: ' + JSON.stringify(response.data));
         })
}, []);

const onChangehandler = (e) => {
    e.preventDefault();
    var name = e.target.name;
    var value = e.target.value;
    setInfo(previous => {
        return {...previous, [name]: value};
      });
};

const onSubmithandler = (e) => {
    e.preventDefault();
    if (Info.newPassword!==Info.confirmNewPassword) {
        setMsg('Passwords do not match');
        return;
    };
    axios.post(API_URL + currentUrl, 
       {newPassword: Info.newPassword,
        confirmNewPassword: Info.confirmNewPassword,}, 
        {
        Headers: {
            'content-type': 'application/json'
        }
    })
    .then(res=>{
        if (res.status===200) {
            alert('Password Successfully Reset!');
            //console.log(res);
        }    
    })
    .catch(err=>{
        alert('Password Failed to be Reset!');
        //console.log(err);
    });
};

return (
    isLoading? (
            <Space direction='vertical' style={{height:'380px', marginTop:'20%', width:'100%'}} >
                <Spin tip="Loading..." size="small">
                   <div className="content" />
                </Spin>
            </Space>):
    (Info.isTokenValid?
    (<div className="aboutus">
      <p>Reset Password</p>
      <Form className="containers">
              <Form.Item label='New Password' style={{margin: 'auto', width: '80%'}}>
                <Input style={{borderRadius:'9px'}} type="text"
                       name="newPassword"
                       value={Info.newPassword}
                       onChange={onChangehandler}/>
              </Form.Item>
              <Form.Item label='Confirm' style={{margin: 'auto', marginTop:'2%', width: '80%'}}>
                <Input style={{borderRadius:'9px'}} type="text"
                       name="confirmNewPassword"
                       value={Info.confirmNewPassword}
                       onChange={onChangehandler}/>
              </Form.Item>
        </Form>
        {msg===""? (<div></div>): <Alert message={msg} 
                                               type='warning' 
                                               style={{margin: 'auto'}} showIcon closable/>}
        <Button style={{marginTop:'2%'}} onClick={onSubmithandler} type="primary" shape="round" size="medium"
                  >Submit</Button>
    </div>): 
    (
        <div>
            <p>Invalid Link for Resetting New Password!</p>
        </div>
    ))
)
}