import React, {useRef} from "react";
import axios from "axios";
import { Button, Form, Input, } from "antd";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export function VerifyResetPassword () {
  const [Info, setInfo] = useState({
    email: "",
});
const ReCAPTCHARef = useRef(null);
const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;

const onChangehandler = (e) => {
    e.preventDefault();
    var name = e.target.name;
    var value = e.target.value;
    setInfo(previous => {
        return {...previous, [name]: value};
      });
};

const onSubmithandler = (e) => {
    e.preventDefault();
    const RecaptchaToken = ReCAPTCHARef.current.getValue();
    ReCAPTCHARef.current.reset();
    axios.post(API_URL + '/VerifyEmail', 
      {email: Info.email,
       recaptchaToken: RecaptchaToken,
      }, {
        Headers: {
            'content-type': 'application/json'
        }
    })
    .then(res=>{
        if (res.status===200) {
            alert('Password reset link has been sent to your email, please check your emails.')
            //console.log(res);
        }    
    })
    .catch(err=>{
        alert('Failed to send password reset link!')
        //console.log(err);
    });
};

return (
    <div className="aboutus">
      <div>
      <h2>Verify Email</h2>
      <Form style={{margin: 'auto', marginTop: '3%', marginBottom: '3%'}}>
              <Form.Item label='Enter Email Address' style={{}}>
                <Input style={{borderRadius:'9px'}} type="text"
                       name="email"
                       value={Info.email}
                       onChange={onChangehandler}/>
              </Form.Item> 
        </Form>
        <ReCAPTCHA sitekey='6LdG1K4pAAAAAD3nU86VpMzJp-Vlx5V5NewQ6oR_' ref={ReCAPTCHARef}/>
        <Button style={{marginTop:'2%'}} onClick={onSubmithandler} type="primary" shape="round" size="medium"
                  >Submit</Button>
      </div>
    </div>
)
}