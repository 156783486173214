import React from "react";
import Pfooter from './Pfooter';
import Pheader from './Pheader';
import { Content } from 'antd/lib/layout/layout';
import { CartProvider } from './cart-hooks.js';
import App from './App';
import { useUser} from './user-hooks.js';

export function Main () {
    const {user, userInfo, dashBoard} = useUser();
    const props = {user, userInfo, dashBoard};
    return (
      <div className='main'>
        <Pheader {...props} />
        <Content>
          
            <CartProvider>
              <App {...props} />
            </CartProvider>
          
        </Content>
        <Pfooter />
      </div>
    );
}