import React from "react";
import { Divider, Descriptions } from "antd";
import { useLocation } from "react-router-dom";

export function UserDetails () {
    const location = useLocation();
    const user = location.state;
    return (
        <div>
          <h1>My Account</h1>
          <Divider />
          <Descriptions>
            <Descriptions.Item key='user_id' label="Customer ID">{user.id}</Descriptions.Item>
            <Descriptions.Item key='user_name' label="User Name">{user.user_name}</Descriptions.Item>
            <Descriptions.Item key='mobile_number' label="Mobile Number">{user.mobile_number}</Descriptions.Item>
            <Descriptions.Item key='user_email' label="Email">{user.email}</Descriptions.Item>
          </Descriptions>
        </div>
    )
}