import React from "react";
import {CloseOutlined} from '@ant-design/icons';
//import { Button } from "antd";
import { useCart } from "./cart-hooks";
//import img from "./imgs/2DF_803.jpg";
//import "antd";
import "./cart.css";


export function CartItem({item}) {

    const {increaseItemNum, reduceFromCart, removeFromCart} = useCart();
    const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;
    //console.log('item: '+JSON.stringify(item));//API_URL+'/uploads/'+item.imgs.icons[0]

    return ( 
                <tr>
                    <img className="cartItemImage" alt={item.name} src={API_URL+'/uploads/'+item.imgs.icons[0]} /> 
                    <td height='14%'>{item.name}</td>
                    <td>{item.description} {!Object.is(item.selections, {}) && Object.entries(item.selections).map(feature => feature[1]) }</td>
                    <td>$ {item.price}</td>
                    <td>
                        <button onClick={() => reduceFromCart(item)}>-</button>
                        <span>    {item.quantity}    </span>
                        <button onClick={() => increaseItemNum(item)}>+</button>
                    </td>
                    <td>$ {item.price*item.quantity}</td>
                    <td>
                        <button onClick={() => removeFromCart(item)}><CloseOutlined /></button>
                    </td>
                </tr>
            );
}