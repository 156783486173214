import React from "react";

export function Aftersale () {
    return (
      <div className="aboutus">
        <div className="aboutcontent">
          <h1>After Sale</h1>
          <p></p>
        </div>
      </div>
    );
}