import React from "react";
//import img from "./imgs/2DF_803.jpg";


export function CartSummary({item}) {

    const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;
    
    return ( 
                <tr>
                    <img style={{maxWidth:'100%'}} alt={item.name} src={API_URL+'/uploads/'+item.imgs.icons[0]} />
                    <td> {item.quantity}</td>
                    <td>$ {item.price*item.quantity}</td>     
                </tr>
            );
}