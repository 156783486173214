import React, {useState} from "react";
import { AutoComplete, Carousel, Descriptions, Radio, Alert, Space } from "antd";
import "./product.css";
//import axios from "axios";
//import { useCart } from "./cart-hooks";
import { Button, Tabs, Rate, message } from "antd";
import { useLocation } from "react-router-dom";
import { ReviewList } from "./ReviewList";
//import { useUser } from './user-hooks.js';
import { useCart } from './cart-hooks';

export function Product() {

  const location = useLocation();
  const product = location.state;
  
  const productItem = product;
  //console.log('productItem data: ' + JSON.stringify(productItem));
  //const {addToWatchlist} = useUser();
  const {addToCart} = useCart();

  //const {increaseItemNum, reduceFromCart, addToCart} = useCart();
  const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;

  const [value, setValue] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(product);
  const [optionmsg, setOptionmsg] = useState('');
  const onValueChange = (e) => {
    e.preventDefault();
    //console.log('radio checked', e.target.value);
    var name = e.target.name;
    var value = e.target.value;
    setValue(e.target.value);
    setSelectedProduct(previous => {
      return {...previous, 
              selections: {...previous.selections, [name]: [value]}
      }
    });
  };

  const add = (selectedProduct) => {
    var chosen = true;
    if (!Object.is(selectedProduct.selections, {})) {
      Object.entries(selectedProduct.selections).forEach(feature => {
        if (feature[1].length>1) chosen = false;
      });
    };
    if (!chosen) {
      setOptionmsg('Please choose your option.');
      //alert('Please choose your option.');
    }
    else {
      addToCart(selectedProduct);
      message.success({content:'Successfully Added to Your Cart!', style:{marginTop:'16vh',},}, 3);
    }
  };



    return (
        
          <div key={product.id} className="product_page">
              <Carousel autoplay style={{margin:'auto', textAlign: 'center'}}>
                {productItem.imgs.largs.map(larg => (
                  <div key={larg}>
                      <img className="img" src={API_URL+'/uploads/'+larg} alt="" style={{margin: 'auto', width:'100%', height: AutoComplete}}/>
                  </div>
                  ))}
               </Carousel>

               <div>
                    <h2>{product.name}</h2>
                    <p>{product.description}</p>
                    <h3>$ {product.price}</h3>

                    <div>
                        <span>Customer Ratings:  </span>
                        <Rate disabled allowHalf defaultValue={4.5} />
                    </div>

                    {Object.is(product.selections, {})? (<div></div>) :

                    (<div style={{marginTop:'1%'}}>
                      {Object.entries(product.selections).map(feature => {
                        return (
                          <div>
                            <h3>Choose your {feature[0]}: </h3>
                            <Radio.Group  style={{maxWidth:'100%', margin:'auto'}} rules={[{required: true,
                                                  message: 'Colour is required!'}]} name={feature[0]} onChange={onValueChange} value={value}>
                              
                                {feature[1].map(option => <Radio key={option} value={option}>{option}</Radio>)}
                              
                            </Radio.Group>  
                          </div>
                        );
                    })}
                  </div>)}

                  {optionmsg===""? (<div></div>): <Alert message={optionmsg} 
                                             type='warning' 
                                             style={{margin: 'auto', marginTop:'1%'}} showIcon closable/>}     
                    
               </div>
               <div style={{display:'flex', justifyContent:'flex-end'}}>
               <Button style={{backgroundColor:'#005DE3', borderColor:'#005DE3', marginTop:'2%'}} type="primary" shape="round" size="large" onClick={() => {
                                               add(selectedProduct);
                                               }}>Add to Cart</Button>
               </div>
               <div style={{marginTop:'1.5%'}}>
                   <Tabs defaultActiveKey="1"
                         type="card"
                         size="small"
                         items={[{
                           label: 'Specifications',
                           key: 'Specifications',
                           children: (<Descriptions>
                                      { 
                                        Object.entries(product.specifics).map(feature => {
                                          return (<Descriptions.Item key={feature[0]} label={feature[0]}>{feature[1]}</Descriptions.Item>);
                                        }

                                        )
                                      }
                                      </Descriptions>)
                         },
                           {
                           label: 'Features',
                           key: 'Features',
                           children: (product.imgs.specs.map(spec => (
                            <img style={{width:'100%', height: AutoComplete}} alt="img" className="img-responsive product-img" src={API_URL+'/uploads/'+spec} />
                           ))),
                         }
                         ]} />
                  
               </div>

               <div style={{marginTop:'3%'}}>
                   <Tabs defaultActiveKey="1"
                         type="card"
                         size="small"
                         items={[{
                           label: 'Customer Reviews',
                           key: 'Customer Reviews',
                           children: (<div>
                                          <ReviewList product={product}/>
                                      </div>)
                         }]} />
               </div>
          </div>
        
    );}