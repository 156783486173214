import React from 'react';
//import electronic_data from "./electronic.json";
//import {Button} from 'antd';
//import "antd";
import "./itemGrid.css";
import img from "./imgs/2DF_801.jpg";
import { useState } from "react";
import electronic_data from "./electronic.json";
import { useCart } from './cart-hooks';
import { Link } from 'react-router-dom';
import { message, Space } from 'antd';

//console.log(electronic_data);

//const {Meta} = Card;

export function Electronic() {
    //const electronic_items = () => JSON.parse(JSON.stringify(electronic_data));
    
    const {addToCart} = useCart();

    const data = useState(electronic_data);
    const products = data[0];

    

    return (
        
         <div className='flex-container'>
            
                {products.map(product => (
            <div key={product.id} className='flex-item'>
               <div className="img">
                <Link key={product.id} to={"/product/"+product.id} state={product}>
                   <img alt={product.name} className="img-responsive product-img" src={img} />
                </Link>
                </div>
                <h4>
                {product.description}
                </h4>
                <h5>
                by {product.brand_name}
                </h5>
                <div>
                <span><h4>{product.price}</h4></span>
                <span>
                    <Space>
                       <button onClick={() => {
                                               addToCart(product);
                                               message.success({content:'Successfully Added to Your Cart!', style:{marginTop:'16vh',},}, 3);}}>加入购物车</button>
                    </Space>
                </span>
                </div>
            </div>))}
        </div>
        
    )
}