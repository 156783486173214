import React from "react";
import { CartList } from "./cartList";
//import electronic_data from "./household.json";
//import { useState } from "react";
import { useCart } from "./cart-hooks";
import { Button, Divider } from "antd";
import {useNavigate} from 'react-router-dom';
import "./cart.css";



export function Cart() {

    //const data = useState(electronic_data);
    //const cartItemsData = data[0];
    const {cart} = useCart();
    
    const navigate = useNavigate();

    return (
      <div className="aboutus">
        <div className="cart">
          <h1>My Cart</h1>
        <div className="cartTable">
          <CartList cartItems={cart} />
        </div>
        <div className="divider">
          <Divider />
        </div>
        <div className="payButton">
        {cart.length>0 && <Button onClick={()=> navigate("/checkout", {from: "cart"})} type="primary" shape="round" size="large">Checkout</Button>}
        </div>
        </div>
      </div>
    );
}