import React, { useEffect, useState } from "react";
import  secureLocalStorage  from  "react-secure-storage";
import { Steps, Card, Divider, Button } from "antd"; 
import axios from "axios";
import './PendingOrder.css';

export function PendingOrders () {
    
    const loadJSON = key => key && JSON.parse(secureLocalStorage.getItem(key));
    const saveJSON = (key, data) => secureLocalStorage.setItem(key, JSON.stringify(data));
    const user = loadJSON('user');
    const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;
    const localPendingOrders = loadJSON('localPendingOrders');
    const [pendingOrders, setPendingOrders] = useState(localPendingOrders && localPendingOrders.length>0 ? localPendingOrders:[]);

    useEffect(() => {
      axios.post(API_URL + "/pendingOrders", JSON.stringify(user),
      {
          headers:{"Content-Type": "application/json"},
          withCredentials:false,
      })
      .then((response) => {
          const orders = response.data;
          setPendingOrders(orders);
          saveJSON('localPendingOrders', orders);
    })}, []);

    const onCancelOrderhandler = (order) => {
        //removeFromUserOrders(order)?;
        axios.post(API_URL + "/cancelPendingOrders", JSON.stringify(order),
        {
          headers:{"Content-Type": "application/json"},
          withCredentials:false,
        })
        .then((response) => {
          const orders = response.data;
          setPendingOrders(orders);
          saveJSON('localPendingOrders', orders);
        })
    };

    return (
      pendingOrders!==0 && Array.isArray(pendingOrders) ? (
        <div>
          <h3>Pending Orders</h3>
          <div>
            {pendingOrders.map((order) => (
            <div>
            <Steps className="infoSteps"
            progressDot
            current={order.status_code}
            items={[
              {
                title: 'Order Paid',
              },
              {
                title: 'Order Packed',
              },
              {
                title: 'Order Shipped',
              },
              {
                title: 'Order Delivered',
              },
            ]}
            />
            <Card key={order.id} style={{height: '2%', margin:'auto', marginTop: '2.5%',marginBottom: '5%',borderRadius:'5px', boxShadow:'0 4px 8px 0 rgba(201, 189, 232, 0.2)'}}>
              <div className="orderStatus">
                <p style={{textAlign:'left'}} key='date'>Date: {order.date}</p>
                <p style={{textAlign:'left'}} key='status'>Order ID: {order.id}</p>
                <p style={{textAlign:'left'}} key='price'>Status: {order.status}</p>
              </div>
              <Divider/>
              <div>
                {order.items!==0 && Array.isArray(order.items) && order.items.map(item => 
                (<div className="infoitemtable" style={{display:'flex', justifyContent:'space-between', marginBottom:'2%'}}>
                  <img alt={item.id} className="info-product-img" src={API_URL+'/uploads/'+item.imgs.icons[0]} />
                  <div style={{wordWrap:'break-word', maxWidth:'40%'}}>
                    <div>
                      {item.name}
                    </div>
                    <div style={{wordWrap:'break-word'}}>
                      {item.description}
                      {!Object.is(item.selections, {}) && Object.entries(item.selections).map(feature => feature[1]) }
                    </div>
                    <div>
                      $ {item.price}
                    </div>
                  </div>
                  <div>
                     Quantity: {item.quantity}
                  </div>
                </div>))}
              </div>
              <Divider/>
              <div style={{display:'flex', justifyContent:'space-between'}}>
                <div>Total: $ {order.total/100}</div>
                {order.status_code < 2 && <div><Button onClick={() => onCancelOrderhandler(order)} type="link">Cancel Order</Button></div>}
              </div>
            </Card>
            </div>))}
          </div> 
            </div>) : (
              <div style={{margin:'auto', marginTop:'25vh'}}>
                  <div>
                    No Pending Orders Yet.
                  </div>
              </div>
            ))
}
