import React from "react";
//import './AdBlock.css';
import {ProductGrid} from './ProductGrid.js';

export function AdBlock({catalogues}) {
    //console.log('catalogues: '+JSON.stringify(catalogues));
    //Object.keys(catalogues).map(item => {
        //console.log('array item: '+JSON.stringify(catalogues[item]));
    //});;
    
    return (
        <div>
            {
               Object.keys(catalogues).map(item => (
                <ProductGrid key={item}
                         catalogue={catalogues[item]} />
               ))
            }
        </div>
    )
}