import React, { useState, useEffect } from "react";
import {AdBlock} from './AdBlock.js';
import axios from "axios";

export function Home() {

    const [catalogue, setCatalogue] = useState({});
    const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;

    //var catlog = {};

    useEffect(() => {
    //console.log('currentUrl: ' + API_URL + currentUrl);
      axios.get(API_URL,
      {
        headers:{"Content-Type": "application/json"},
        withCredentials:false,
      })
         .then(response => {
            setCatalogue(response.data);
            //catlog = response.data;
            //setIsloading(false);
            //console.log('response data: ' + JSON.stringify(response.data));
         })
    }, []);

    return (
        <div>
          <div>
          <AdBlock catalogues={catalogue}/>
          </div>
        </div>
    )
}